<template>
  <v-container fluid>
    <div>
      <div class="">
        <div>
          <v-row>
            <v-col cols="12" md="3">
              <div class="container">
                <h3 class="">Authentification des documents</h3>
                <v-select
                  :items="['Badge Scolaire', 'Reçu']"
                  onchange="initialize"
                  v-model="documentName"
                  label="Choisissez le document"
                ></v-select>

                <div v-if="loading" class="d-flex justify-center">
                  <v-btn loading icon></v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="9">
              <div v-if="documentName" class="container">
                <div v-if="!response" class="qr-code-stream">
                  <qrcode-stream @decode="onDecode"></qrcode-stream>

                  <div class="d-flex justify-center mt-5">
                    <v-icon
                      color="black"
                      size="3rem"
                      class="ml-2 elevation-1 rounded px-2 py-2"
                      >mdi-qrcode-scan</v-icon
                    >
                  </div>
                </div>
                <div v-else>
                  <div v-if="documentName == 'Reçu'">
                    <div>
                      <div>
                        <h3>
                          Recu N° {{ response.receiptCode }}
                          <v-icon color="green">mdi-check-decagram</v-icon>
                        </h3>
                        <p class="mb-2">
                          <span>Nom et Prénoms : </span>
                          {{ response.student.firstName }}
                          {{ response.student.lastName }}
                        </p>
                        <p class="mb-2">
                          <span>Montant : </span> {{ response.amount }} F CFA
                        </p>
                        <p class="mb-2">
                          <span>Libellé : </span> {{ response.object }}
                        </p>
                        <p class="mb-2">
                          <span>Date : </span> {{ response.createdAt }}
                        </p>
                      </div>
                      <div class="mt-3">
                        <v-btn @click="initialize" class="elevation-0 white"
                          >Reprendre
                          <v-icon color="green" class="ml-2"
                            >mdi-qrcode</v-icon
                          ></v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div v-if="documentName == 'Badge Scolaire'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <div>
                          <div>
                            <h3>
                              Matricule N° {{ response.registrationNumber }}
                              <v-icon color="green">mdi-check-decagram</v-icon>
                            </h3>
                            <p class="mb-2">
                              <span>Nom et Prénoms : </span>
                              {{ response.firstName }}
                              {{ response.lastName }}
                            </p>
                            <p class="mb-2">
                              <span>Nationalité : </span>
                              {{ response.nationality }}
                            </p>
                            <p class="mb-2">
                              <span>Date de naissance : </span>
                              {{ response.birthday }}
                            </p>
                            <p class="mb-2">
                              <span>Option : </span> {{ response.option.name }}
                            </p>
                            <p class="mb-2">
                              <span>Dégré : </span>
                              {{ response.translatedLevel }}
                            </p>
                            <p class="mb-2">
                              <span>Numéro pièce d'identité : </span>
                              {{ response.IDNumber }}
                            </p>
                          </div>
                          <div class="mt-3">
                            <v-btn @click="initialize" class="elevation-0 white"
                              >Reprendre
                              <v-icon color="green" class="ml-2"
                                >mdi-qrcode</v-icon
                              ></v-btn
                            >
                          </div>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6" class="">
                          <v-img  :src="`${response.avatar}`" class="rounded" />
                      </v-col>
                    </v-row>

                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import {
  QrcodeStream,
  // QrcodeDropZone,
} from "vue-qrcode-reader";

import { defaultMethods, messages } from "@/utils/data";

import axios from "axios";
export default {
  components: {
    QrcodeStream,
    // QrcodeDropZone,
  },
  data() {
    return {
      url: "",
      documentName: "",
      response: null,
      loading: false,
    };
  },
  methods: {
    async onDecode(decodedString) {
      // ...
      this.loading = true;
      await this.authentified(decodedString);
      this.loading = false;
    },
    authentified(id) {
      return new Promise((resolve, reject) => {
        if (this.documentName == "Reçu") {
          axios
            .get(`operation/${id.replaceAll('"', "")}`)
            .then((response) => {
              console.log(response);
              this.response = response.data.data;
              resolve(true);
            })
            .catch((err) => {
              reject(err);
              console.log(err);
              defaultMethods.dispatchError(
                this.$store,
                messages.failedToLoad("ce recu")
              );
            });
        }
        if (this.documentName == "Badge Scolaire") {
          axios
            .get(`student/${id.replaceAll('"', "")}`)
            .then((response) => {
              console.log(response);
              this.response = response.data.data;
              resolve(true);
            })
            .catch((err) => {
              reject(err);
              console.log(err);
              defaultMethods.dispatchError(
                this.$store,
                messages.failedToLoad("ce recu")
              );
            });
        }
      });
    },
    initialize() {
      this.response = null;
    },
  },
};
</script>

<style>
.qr-code-stream {
  padding: 2rem 8rem;
}


@media (max-width: 500px) {
  .qr-code-stream {
    padding: 1rem 1rem;
  }
}
</style>